.gameboy {
  background: url("../../assets/images/gameboy.png");
  background-size: contain;
  width: 259px;
  height: 400px;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 250px);
  left: 18%;
  z-index: 101;

  .switch {
    position: absolute;
    width: 15px;
    height: 8px;
    background: #ffeb9f;
    left: 30px;
    top: -8px;
    border-radius: 4px 4px 0 0;
    border: 2px solid #2d53f7;
    border-width: 1.5px 1.5px 0px 1.5px;
    transition: left 0.3s;
    cursor: pointer;
  }

  .loading-screen {
    position: absolute;
    width: 127px;
    height: 113px;
    background: #bdae58;
    box-shadow: inset 3px 3px rgba(0, 0, 0, 0.15);
    top: 51px;
    left: 58px;
    overflow: hidden;
    transform: rotate(-2.5deg);

    h1 {
      color: #8a891f;
      font-size: 16px;
      font-weight: 900;
      z-index: 10;
      text-align: center;
      animation: console 10s infinite ease-in-out;
      margin-top: -25px;
    }
  }
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  cursor: pointer;
  span {
    color: #fff;
    font-size: 50px;
  }
}

.gameboy-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 102;
  input {
    opacity: 0;
  }
}

.gameboy-window-inner {
  background: rgba(121, 255, 206, 1);
}

@keyframes console {
  0% {
    margin-top: -15px;
  }
  20% {
    margin-top: -15px;
  }
  30% {
    margin-top: 49px;
  }
  80% {
    margin-top: 49px;
  }
  90% {
    margin-top: 112px;
  }
  100% {
    margin-top: 112px;
  }
}
