.save-playlist {
  position: fixed;
  // height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: #ffe582;
  padding: 40px 20px 0;
}
