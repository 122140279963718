.playlist {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;
  @media (min-width: 750px) {
    padding-bottom: 0;
    padding-top: 0;
  }

  .desktop-wrapper {
    opacity: 0;
    transform: translateY(80px);
    transition: 500ms;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &.loaded {
    .desktop-wrapper {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .desktop-section > p {
    text-align: center;
    display: block;
    line-height: 1.2;
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #fff;
    font-weight: 900;
  }

  .emoji {
    display: block;
    margin: 0 auto;
    font-size: 4em;
    text-align: center;
    animation: float 3s ease-in-out infinite;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    .btn {
      margin: 0 15px 0 0 !important;
    }
  }

  .btn {
    border-radius: 50px;
    background: #fff;
    text-align: center;
    display: block;
    margin: 20px auto 0 auto;
    cursor: pointer;
    transition: 200ms;
    span {
      display: block;
      text-align: center;
    }

    &.shuffle {
      width: 170px;
      margin-top: 0;
      padding: 13px;
      margin: 0;
      background: #0080ff;
      span {
        color: #fff;
        font-weight: 900;
        font-size: 14px;
      }
    }

    &.spotify {
      background: #000;
      width: 100%;
      max-width: 400px;
      text-align: left;
      span {
        font-weight: 900;
        font-size: 20px;
        position: relative;
        color: white;
        width: 100%;
        height: 100%;
        display: block;
        padding: 23px 30px 23px 30px;

        i {
          font-size: 43px;
          color: #1ed760;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &.youtube {
      background: #fff;
      width: 100%;
      max-width: 400px;
      text-align: left;
      span {
        font-weight: 900;
        font-size: 20px;
        position: relative;
        color: #000;
        width: 100%;
        height: 100%;
        display: block;
        padding: 23px 30px 23px 30px;

        i {
          font-size: 43px;
          color: red;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &.again {
      width: 170px;
      margin: 0;
      padding: 13px;

      span {
        font-weight: 900;
        color: #0080ff;
        font-size: 14px;
      }
    }
  }

  @media (hover: hover) {
    .btn:hover {
      transform: scale(1.05);
      &.spotify {
        background: lighten(#000000, 30%);
      }
      &.shuffle {
        background: lighten(#0080ff, 10%);
      }
    }
  }

  .track-list {
    max-width: 100%;
    width: 100%;
    margin: 50px auto;

    @media (min-width: 750px) {
      max-width: none;
      width: 100%;
      margin: 10px auto;
    }
    .track {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0;

      .meta {
        padding-right: 10px;
        .title,
        .artist {
          display: block;
          font-size: 24px;
          color: white;
          line-height: 25px;
        }

        .title {
          margin-bottom: 5px;
          text-transform: uppercase;
          font-weight: 900;
        }
        .artist {
          font-weight: 400;
          font-size: 20px;
        }
      }

      .play-btn {
        cursor: pointer;
        background: #0080ff;
        border-radius: 99999em;
        width: 46px;
        height: 46px;
        position: relative;
        min-width: 46px;
        transition: 200ms;

        i {
          color: #fff;
          position: absolute;
          top: 50%;
          left: calc(50% + 1px);
          transform: translateY(-50%) translateX(-50%);
          font-size: 16px;
        }
      }

      @media (hover: hover) {
        .play-btn:hover {
          transform: scale(1.1);
          background: lighten(#0080ff, 10%);
        }
      }
    }

    .track:first-child {
      margin-top: 0;
    }
  }

  .emoji.desktop-emoji {
    display: block;
    font-size: 7em;
    margin-bottom: 25px;
  }

  .desktop-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    min-height: 100%;
    .desktop-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 0 4vw 0 8vw;
      &:first-child {
        padding: 0 8vw 0 4vw;
      }
    }
  }

  &.theme-green {
    background-color: #00ed9e;
  }

  &.theme-yellow {
    background-color: #ffe52c;
  }

  &.theme-blue {
    background-color: #39d0ff;
  }

  &.theme-pink {
    background-color: #ff97da;
  }

  .social-share {
    margin-top: 10px;
    text-align: center;
    color: white;

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    h4 {
      font-weight: 900;
      font-size: 20px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      color: white;
      text-decoration: none;
      font-size: 40px;
      border-radius: 50px;
      margin: 0 10px;
      transition: 200s;
    }

    a.twitter {
      background: #00acee;
    }

    a.facebook {
      background: #4267b2;
    }

    @media (hover: hover) {
      a:hover {
        transform: scale(1.1);
        &.twitter {
          background: lighten(#00acee, 10%);
        }
        &.facebook {
          background: lighten(#4267b2, 10%);
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .playlist .track-list .track .meta .title {
    font-size: 20px;
  }
  .playlist .track-list .track .meta .artist {
    font-size: 16px;
  }
  .playlist .desktop-section > p {
    font-size: 26px;
  }
  .playlist {
    .btn.spotify,
    .btn.youtube {
      span {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 750px) {
  .playlist .desktop-wrapper {
    flex-direction: column-reverse;
    width: 90%;
    max-width: 600px;
    padding: 100px 0 40px 0;
    margin: 0 auto;
    .desktop-section {
      width: 100%;
    }
    .desktop-section {
      padding: 0 !important;
    }
    .track-list {
      margin: 30px 0;
    }
    .btn-container {
      justify-content: center;
      margin-top: -10px;
      .btn {
        margin: 0 5px !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .playlist {
    .desktop-section {
      > p {
        font-size: 24px;
        margin-bottom: 15px;
      }
      .emoji {
        width: 70px;
      }
    }
    .btn {
      margin: 10px auto 0 auto;
    }
  }
  .playlist .social-share {
    margin-bottom: 10px;
    h4 {
      margin-bottom: 15px;
    }
    a {
      width: 60px;
      height: 60px;
      font-size: 30px;
    }
  }
}
