.landing {
  // transition: all 0.5s;
  // min-height: 100vh;
  overflow: hidden;
  position: relative;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .texture-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 10000;
    background-image: url("../../assets/images/texture-overlay.png");
    background-size: cover;
    background-position: center center;
    opacity: 0.5;
  }

  .desk-inner {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    overflow: auto;
  }

  &:before {
    transition: 200ms;
  }

  &.dark {
    // background: #aaa;
    &:before {
      content: "";
      background: black;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999999;
      pointer-events: none;
      mix-blend-mode: darken;
    }
    // > * {
    //   filter: grayscale(80%);
    // }
  }

  .help-icon {
    display: none;
  }
}

.lightbulb {
  width: 100%;
  max-width: 221px;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  transition: 1500ms;
}

.start-faded {
  opacity: 0.15;
  pointer-events: none;
  z-index: 500;
}

.loaded {
  .lightbulb {
    transform: translateX(-50%) translateY(0);
  }
  .start-faded {
    opacity: 1;
    pointer-events: all;
    transition: opacity 1000ms 300ms;
  }
}

.landing-logo {
  width: 100%;
  max-width: 335px;
  display: block;
  position: relative;
  left: 20px;
  animation: float 3s ease-in-out infinite;
}

.theme-green {
  background-color: #00ed9e;
}

.theme-yellow {
  background-color: #ffe52c;
}

.theme-blue {
  background-color: #39d0ff;
}

.theme-pink {
  background-color: #ff97da;
}

.landing.konami {
  animation: 1s lightKonami infinite;
}

@keyframes lightKonami {
  0% {
    background-position: center -1100px, center;
  }
  50% {
    background-position: center -700px, center;
  }
  100% {
    background-position: center -1100px, center;
  }
}

@keyframes float {
  0% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(4px);
  }
}

@media (max-height: 800px) and (orientation: landscape) {
  .mood {
    transform: scale(0.9);
    transform-origin: top center;
  }
  .gameboy {
    top: calc(50% - 240px) !important;
  }
  .lightbulb {
    top: -80px;
  }
  .record {
    bottom: -80px !important;
  }
}

@media (max-height: 750px) and (orientation: landscape) {
  .mood {
    transform: scale(0.8);
    transform-origin: top center;
  }
  .light-switch {
    display: none;
  }
  .basketball {
    top: 5% !important;
  }
}

@media (max-height: 700px) and (orientation: landscape) {
  .mood {
    display: none;
  }
  .gameboy {
    top: calc(50% - 330px) !important;
  }
  .lightbulb {
    display: none;
  }
}

@media (max-height: 650px) and (orientation: landscape) {
  .shelf {
    display: none;
  }
}

@media (max-width: 1230px) {
  .mood {
    display: none;
  }
  .help-case {
    display: none;
  }
  .calendar {
    display: none;
  }
  .gameboy {
    left: 9% !important;
    top: calc(50% - 300px) !important;
  }
  .shelf {
    display: none;
  }
  .landing-logo,
  .emoji-input,
  .theme-swtich {
    top: -12vh;
  }
  .theme-swtich {
    margin-top: 10px !important;
  }
  .doodlepad-container {
    left: 34% !important;
  }
  .landing {
    .help-icon {
      display: block;
    }
    .light-switch {
      display: none;
    }
    .info-trigger {
      top: -27px;
    }
    .spin-lines {
      top: 37px;
    }
  }
}

@media (max-width: 1000px) {
  .gameboy {
    left: -5% !important;
  }
  .record {
    bottom: -80px !important;
    left: -55px !important;
  }
}

@media (max-width: 767px) {
  .gameboy {
    display: none;
  }
  .record {
    display: none;
  }
  .shelf {
    display: none;
  }
  .doodlepad-container {
    display: none;
  }
  .landing-logo,
  .emoji-input,
  .theme-swtich {
    top: 0;
  }
  .theme-swtich {
    margin-top: 10px !important;
  }
  .basketball {
    display: none;
  }
}

@media (max-width: 500px) {
  .landing-logo {
    max-width: 300px;
    left: 10px;
  }
  .emoji-input {
    margin: 0 0 10px 0 !important;
    h1 {
      margin-bottom: 15px !important;
      font-size: 16px !important;
    }
  }
  .theme-swtich {
    margin-top: 15px !important;
  }
  .info-trigger {
    top: -50px !important;
  }
  .spin-lines {
    top: 13px !important;
  }
  .light-switch {
    top: -42px !important;
    transform: scale(0.8);
  }
}

@media (max-width: 750px) and (max-height: 1000px) {
  .lightbulb {
    display: none;
  }
}
@media (max-width: 750px) and (min-height: 650px) {
  .lightbulb {
    display: block;
  }
}

@media (max-width: 1230px) and (max-height: 850px) {
  .gameboy {
    left: 1% !important;
    top: -1% !important;
  }
}

@media (max-height: 920px) and (max-width: 1200px) {
  .doodlepad-container {
    display: none;
  }
  .landing-logo,
  .emoji-input,
  .theme-swtich {
    top: 0;
  }
}
