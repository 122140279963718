.info-trigger {
  position: absolute;
  top: 0;
  left: 5%;
  width: 75px;
  cursor: pointer;
  z-index: 10;
}

.spin-lines {
  position: absolute;
  top: 63px;
  left: 5%;
  z-index: 11;
  width: 63px;
  pointer-events: none;
  animation: spinning 5s ease-in-out infinite;
}

.info {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;

  .info-inner-content {
    background: #fff;
    width: 80%;
    max-width: 600px;
    max-height: 90%;
    border-radius: 25px;
    padding: 40px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-30%);
    opacity: 0;
    overflow: auto;
    cursor: default;

    .close-modal {
      position: absolute;
      top: -30px;
      right: -30px;
      width: 26px;
      height: auto;
    }

    h2 {
      margin: 40px 0 20px 0;
      font-size: 20px;
      font-weight: 900;
    }

    h2:first-child {
      margin-top: 0;
    }

    p,
    ul {
      font-size: 16px;
      margin: 0;
    }

    ul {
      padding: 0 0 0 20px;
      margin-bottom: 20px;
      color: #0080ff;
    }
  }
}

.info.open {
  z-index: 200;
  transition: 200ms;
  opacity: 1;
  pointer-events: all;
  .info-inner-content {
    transition: 600ms;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.theme-pink {
  .info {
    background: transparentize(lighten(#ff97da, 5%), 0.1);
  }
}
.theme-green {
  .info {
    background: transparentize(lighten(#00ed9e, 5%), 0.1);
  }
}
.theme-yellow {
  .info {
    background: transparentize(lighten(#ffe52c, 5%), 0.1);
  }
}
.theme-blue {
  .info {
    background: transparentize(lighten(#39d0ff, 5%), 0.1);
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(3240deg);
  }
  100% {
    transform: rotate(3240deg);
  }
}

@media (max-width: 500px) {
  .info {
    .info-inner-content {
      width: 90%;
      padding: 40px 20px;
    }
  }
}
