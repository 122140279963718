.chat {
  margin: 0;
  max-height: 100%;
  padding: 20px 10px;
  .message {
    display: flex;
    margin-bottom: 15px;
    align-items: center;

    &.user {
      .profile-inner {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  .profile {
    .profile-inner {
      background: #fff;
      border-radius: 999em;
      margin-right: 20px;
      position: relative;
      width: 70px;
      height: 70px;

      img,
      span {
        padding: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 80%;
      }

      span {
        display: block;
        font-size: 2.5em;
        padding: 0;
        width: 39px;
      }
    }
  }

  .message-text {
    background: #0080ff;
    border-radius: 15px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    span {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .message.user .message-text {
    background: #fff;
    span {
      color: #0080ff;
    }
  }

  .user-response-container {
    padding-left: 90px;
  }

  .user-response {
    margin-top: 20px;
    textarea {
      width: 100%;
      background: #fff;
      outline: none;
      font-weight: 400;
      font-size: 16px;
      resize: none;
      padding: 20px 30px;
      border-width: 0px;
    }
  }

  .user-response-buttons {
    display: flex;
    margin-top: 20px;

    .user-response-button {
      border-radius: 999em;
      width: 130px;
      text-align: center;
      background: #fff;
      cursor: pointer;
      margin-right: 15px;
      transition: 200ms;
      &.primary {
        background: #0080ff;
        span {
          color: white;
          font-weight: 900;
        }
      }
      span {
        font-weight: 500;
        display: block;
        padding: 13px;
        color: #0080ff;
        font-size: 14px;
      }
      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    @media (hover: hover) {
      .user-response-button:hover {
        background: lighten(#fff, 10%);
        transform: scale(1.05);
        &.primary {
          background: lighten(#0080ff, 10%);
        }
      }
    }

    &.multi {
      display: block;
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;

      .user-response-button {
        margin-bottom: 10px;
        width: 100%;
        max-width: 140px;
        span {
          font-weight: 900;
        }
      }
    }
  }

  .date-picker {
    border: 0;
    margin-bottom: 15px;

    > div {
      border: 0;
      border-radius: 10px;

      .css-1hwfws3 {
        padding: 10px;
      }

      .css-151xaom-placeholder,
      .css-dvua67-singleValue {
        color: #0080ff;
        font-weight: 900;
        top: 50%;
      }
    }

    .css-1492t68,
    .css-va7pk8 {
      color: #2d53f7;
      font-weight: 900;
    }

    .css-d8oujb {
      background: #2d53f7;
    }

    .css-1aya2g8 {
      border-radius: 0;
    }

    .css-1aya2g8 {
      border-color: transparent !important;
    }

    .css-kj6f9i-menu {
      top: auto;
      bottom: 0;
    }

    svg {
      fill: #2d53f7;
    }
  }
}

.message-enter {
  opacity: 0.01;
  transform: translateY(20px);
}

.message-enter.message-enter-active {
  opacity: 1;
  transition: 400ms;
  transform: translateY(0);
}

.message-leave {
  opacity: 1;
  transform: translateY(0);
}

.message-leave.message-leave-active {
  opacity: 0.01;
  transition: 400ms;
  transform: translateY(20px);
}

.typing-indicator {
  $ti-color-bg: #e6e7ed;
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding: 0 0 0 80px;
  display: table;
  margin: 0 auto;
  position: relative;

  span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 3px;
    background-color: #fff;
    display: block;
    border-radius: 50%;
    opacity: 0.3;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 0.5s blink infinite ($i * 0.175s);
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .chat {
    .profile {
      .profile-inner {
        margin-right: 10px;
      }
    }
    .user-response-container {
      padding-left: 80px;
    }
    .message-text {
      padding: 20px 15px;
    }
    .user-response textarea {
      padding: 20px 15px;
    }
    .user-response-buttons {
      flex-direction: column-reverse;
      padding-bottom: 20px;
      .user-response-button:last-child {
        margin-bottom: 10px;
      }
    }
  }
}
