.light-switch {
  background: url("../../assets/images/light-switch.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  width: 65px;
  height: 130px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 4%;
  cursor: pointer;

  .light-switch-button {
    background: url("../../assets/images/light-switch-switch.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    width: 13px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    top: 79px;
    position: absolute;
    cursor: pointer;
  }

  &.lit {
    .light-switch-button {
      transform: translateX(-50%) rotate(0deg);
    }
  }
}
