.calendar {
  position: absolute;
  top: calc(50% - 420px);
  right: 17%;
  z-index: 5;
  cursor: pointer;

  @media (max-height: 800px) {
    top: 0px;
  }

  img {
    width: 204px;
  }

  &.open {
    .question {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
      transition: 400ms;
    }
  }

  .question {
    position: absolute;
    background: rgba(255, 255, 255, 1);
    padding: 10px;
    // border-radius: 4px;
    border: 2px solid #0080ff;
    z-index: 0;
    right: 19px;
    width: 160px;
    height: 216px;
    top: 30px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    transform: translateY(20px);
    > span {
      font-size: 12px;
      color: #0080ff;
      font-weight: 900;
      line-height: 1.3;
      display: block;
      text-align: center;
      // transform: rotate(-4deg);
    }
  }

  .user-response-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;

    .user-response-button {
      border-radius: 999em;
      width: 100%;
      text-align: center;
      background: #0080ff;
      cursor: pointer;
      &.primary {
        margin-top: 5px;
        background: #f0f0f0;
        span {
          color: #0080ff;
        }
      }
      span {
        font-weight: 900;
        display: block;
        padding: 8px 10px;
        color: white;
        font-size: 0.7em;
      }
    }
  }

  @media (max-width: 995px) {
    display: none;
  }
}
