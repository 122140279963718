.doodlepad-container {
  width: 381px;
  position: absolute;
  bottom: -20px;
  right: 12%;

  > div {
    position: absolute;
    top: 40px;
    left: 58px;
    border-radius: 15px;
    overflow: hidden;
  }

  .knob-left,
  .knob-right {
    position: absolute;
    width: 41px;
    top: 230px;
  }

  .knob-left {
    left: 36px;
    animation: knobleft 1.5s infinite linear alternate;
  }

  .knob-right {
    right: 20px;
    animation: knobright 1.5s infinite linear alternate;
  }
}

@keyframes knobleft {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(30deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(25deg);
  }
}

@keyframes knobright {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-90deg);
  }
  45% {
    transform: rotate(-30deg);
  }
  80% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-25deg);
  }
}
