.generator {
  position: fixed;
  // height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  .generator-wrap {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    opacity: 0;
    transition: 500ms;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(80px);

    .generator-loading {
      width: 50%;
    }

    .chat {
      width: 50%;
      padding-right: 15vw;
      // overflow: auto;
    }
  }

  &.loaded .generator-wrap {
    opacity: 1;
    transform: translateY(0px);
  }

  .generator-inner {
    height: 100%;
  }

  .generator-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      display: block;
      line-height: 1.2;
      font-size: 32px;
      margin-bottom: 30px;
      color: #fff;
      font-weight: 900;
    }

    img {
      width: 100px;
      animation: float 3s ease-in-out infinite;
    }
  }

  &.theme-green {
    background-color: #00ed9e;
  }

  &.theme-yellow {
    background-color: #ffe52c;
  }

  &.theme-blue {
    background-color: #39d0ff;
  }

  &.theme-pink {
    background-color: #ff97da;
  }
}

@media (max-width: 950px) {
  .generator {
    .generator-wrap {
      flex-direction: column;
      padding-top: 10vh;
      padding-bottom: 20px;
      overflow: auto;
      .generator-loading {
        width: 100%;
        margin-bottom: 20px;
      }
      .chat {
        width: 90%;
        padding: 0;
        overflow: initial;
        max-width: 500px;
      }
    }
  }
}

@media (max-width: 500px) {
  .generator {
    .generator-loading {
      p {
        font-size: 24px;
        margin-bottom: 15px;
      }
      img {
        width: 70px;
      }
    }
  }
}
