@import "./styleguide/styleguide.scss";

img {
  max-width: 100%;
}

body {
  background: #ff78af;
}

* {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

textarea,
input {
  user-select: all !important;
}
