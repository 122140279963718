.basketball {
  width: 219px;
  position: absolute;
  top: calc(50% - 230px);
  right: 0;

  &.clicked {
    z-index: 200;
  }

  .net {
    position: relative;
    z-index: 5;
    cursor: pointer;
  }

  .ball {
    width: 125px;
    position: absolute;
    top: 0;
    left: 19px;
    z-index: 2;
    animation: float 3s ease-in-out infinite;
    animation-delay: 1.5s;
    transform: translateY(4px);
  }

  &.clicked .ball {
    animation: fall 1s linear forwards;
  }

  .pop {
    position: absolute;
    opacity: 0;
    transform: scale(0) rotate(0deg);
    z-index: 1;
  }

  .pop1 {
    width: 187px;
    top: 60px;
    left: -20px;
  }

  .pop2 {
    width: 141px;
    top: 30px;
    left: 5px;
  }

  .pop3 {
    width: 158px;
    top: 40px;
    left: 3px;
  }

  &.clicked .pop {
    opacity: 1;
    transform: scale(1) rotate(720deg);
    transition: 0.5s 0.5s;
  }
}

.basketball-popover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;

  .inner {
    position: absolute;
    width: 100%;
    max-width: 500px;
    right: 250px;
    top: calc(50% - 230px);
    opacity: 0;
    transform: translateX(30px);

    a {
      color: white;
    }
  }
}

.clicked .basketball-popover {
  opacity: 1;
  pointer-events: all;
  transition: 200ms;

  .inner {
    transform: translateX(0px);
    transition: 600ms 800ms;
    opacity: 1;
  }
}

.theme-pink {
  .basketball-popover {
    background: transparentize(lighten(#ff97da, 5%), 0.1);
  }
}
.theme-green {
  .basketball-popover {
    background: transparentize(lighten(#00ed9e, 5%), 0.1);
  }
}
.theme-yellow {
  .basketball-popover {
    background: transparentize(lighten(#ffe52c, 5%), 0.1);
  }
}
.theme-blue {
  .basketball-popover {
    background: transparentize(lighten(#39d0ff, 5%), 0.1);
  }
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-50px) rotate(180deg);
  }
  100% {
    transform: translateY(1000px) rotate(220deg);
  }
}
