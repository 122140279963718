.record {
  background: url("../../assets/images/cassetteplayer.png");

  width: 380px;
  height: 645px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;

  .speed-switch {
    position: absolute;
    width: 60px;
    height: 130px;
    bottom: 86px;
    left: 230px;
    cursor: pointer;

    .speed-button {
      width: 21px;
      position: absolute;
      z-index: -1;
      transition: 100ms;

      &.slow {
        top: 17px;
        left: 34px;

        &.pressed {
          top: 14px;
          left: 26px;
        }
      }

      &.fast {
        top: 75px;
        left: 21px;

        &.pressed {
          top: 72px;
          left: 13px;
        }
      }
    }
  }

  .on-off {
    cursor: pointer;
    bottom: 288px;
    left: 66px;
    position: absolute;
    width: 100px;
    height: 50px;

    .startbutton {
      position: absolute;
      width: 48px;
      height: auto;
      top: 3px;
      left: -1px;
      z-index: -1;
      transition: 100ms;

      &.pressed {
        top: 10px;
        left: -2px;
      }
    }

    .stopbutton {
      position: absolute;
      width: 33px;
      height: auto;
      left: 60px;
      top: 17px;
      z-index: -1;
      transition: 100ms;

      &.pressed {
        top: 23px;
        left: 58px;
      }
    }
  }

  .reel-top,
  .reel-bottom {
    width: 36px;
    position: absolute;

    &.playing {
      animation: spin 2s linear infinite;
    }
  }

  .reel-top {
    bottom: 153px;
    left: 77px;
  }

  .reel-bottom {
    bottom: 47px;
    left: 53px;
  }

  .sound-lines {
    position: absolute;
    width: 61px;
    left: 93px;
    top: -16px;
    opacity: 0;

    &.playing {
      opacity: 1;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
