.mood {
  width: 299px;
  height: 140px;
  background: url("../../assets/images/mood.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  top: 0px;
  left: 16%;

  .hands {
    position: absolute;
    width: 100%;
    top: 7px;
    height: 40px;

    img {
      position: absolute;
      top: 0;
      width: 30px;
      opacity: 0.3;
    }

    .hand1 {
      left: 72px;
    }

    .hand2 {
      width: 25px;
      left: 132px;
    }

    .hand3 {
      left: 190px;
    }
  }

  .runner {
    position: absolute;
    width: 100%;
    bottom: 30px;
    height: 25px;

    .one,
    .two,
    .three {
      position: absolute;
      width: 50px;
      height: 40px;
      cursor: pointer;
      top: -5px;
    }

    .one {
      left: 70px;
    }

    .two {
      left: 120px;
    }

    .three {
      left: 170px;
    }
  }

  img {
    width: 37px;
    position: absolute;
    cursor: pointer;
    top: 78px;
    left: 80px;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &.mood-1 {
    img {
      left: 75px;
    }

    .hand1 {
      opacity: 1;
    }
  }
  &.mood-2 {
    img {
      left: 127px;
    }
    .hand2 {
      opacity: 1;
    }
  }
  &.mood-3 {
    .hand3 {
      opacity: 1;
    }
    img {
      left: 180px;
    }
  }
}
