.social {
  position: absolute;
  bottom: 30px;
  right: 30px;

  label {
    position: absolute;
    transform: rotate(90deg);
    white-space: nowrap;
    bottom: 84px;
    right: -34px;
    color: white;
    font-size: 20px;
    font-weight: 900;
  }

  .social-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin: 12px 0;
      transition: 200ms;
    }

    @media (hover: hover) {
      a:hover {
        transform: scale(1.2);
      }
    }

    .seeme {
      width: 24px;
    }

    .instagram,
    .youtube {
      width: 26px;
    }

    .facebook {
      width: 13px;
    }

    .twitter,
    .email {
      width: 27px;
    }
  }
}

@media (max-width: 500px) {
  .social {
    margin-top: 10px;
    position: static;
    label {
      display: none;
    }
    .social-inner {
      flex-direction: row;
      a {
        margin: 0 10px;
      }
    }
  }
}
