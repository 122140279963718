.theme-swtich {
  background: url("../../assets/images/theme-switch.png");
  width: 140px;
  height: 156px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  .theme-pointer {
    width: 83px;
    height: 83px;
    position: absolute;
    top: 57%;
    left: 51.5%;
    transform: translateY(-50%) translateX(-50%);
    transform-origin: center;
    transition: transform 0.3s;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    background: url("../../assets/images/theme-switch-pointer.png");
    background-size: contain;
  }
}

.theme-select-0 {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 12px;
  left: 11px;
}

.theme-select-1 {
  position: absolute;
  width: 25px;
  height: 25px;
  top: -2px;
  left: 41px;
}

.theme-select-2 {
  position: absolute;
  width: 25px;
  height: 25px;
  top: -2px;
  left: 74px;
}

.theme-select-3 {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 12px;
  left: 104px;
}

.theme-pink .theme-pointer {
  transform: translateY(-50%) translateX(-50%) rotate(3deg);
}

.theme-green .theme-pointer {
  transform: translateY(-50%) translateX(-50%) rotate(49deg);
}
.theme-yellow .theme-pointer {
  transform: translateY(-50%) translateX(-50%) rotate(-21deg);
}

.theme-blue .theme-pointer {
  transform: translateY(-50%) translateX(-50%) rotate(24deg);
}

@media (max-width: 500px) {
  .theme-swtich {
    transform: scale(0.7);
    margin-top: -10px !important;
    margin-bottom: -20px;
  }
}
