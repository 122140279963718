.emoji-board {
  position: fixed;
  top: -50px;
  right: 0;
  left: 0;
  bottom: -50px;
  display: flex;
  flex-direction: column;
  padding-top: calc(50px + 10vh);
  overflow: auto;
  align-items: center;
  z-index: 500;
  opacity: 0;
  pointer-events: none;
  transform: translateY(80px);

  &.in {
    opacity: 1;
    pointer-events: all;
    transition: 500ms;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(0);
  }

  .chat {
    display: block;
    width: 100%;
    max-width: 500px;
  }

  .emoji-board-inner {
    width: 90%;
    max-width: 600px;
    background: #fff;
    border-radius: 25px;
    padding: 55px 45px;
  }
}

.emoji-board-top {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 40px;

  .emoji-btn {
    border-radius: 999em;
    padding: 10px;
    display: block;
    width: 190px;
    transition: all 0.3s;
    text-align: center;
    transition: 200ms;

    span {
      font-size: 14px;
      font-weight: 400;
      color: white;
    }

    &.blue {
      background: #0080ff;
      span {
        font-weight: 900;
      }
    }

    &.red {
      background: #ff78af;
    }

    &.disabled {
      opacity: 0.2;
    }
  }

  @media (hover: hover) {
    .emoji-btn:hover {
      background: lighten(#0080ff, 10%);
      transform: scale(1.05);
      &.red {
        background: lighten(#ff78af, 10%);
      }
    }
  }

  .emoji-btn-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }

  .emoji {
    background: #f3f3f3;
    border-radius: 999em;
    position: relative;
    width: 80px;
    height: 80px;
    img {
      max-width: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.emoji-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 440px;
  margin: 0 auto;

  .emoji {
    padding: 7px;
    transition: 100ms;
    img {
      width: 30px;
      cursor: pointer;
    }
  }

  @media (hover: hover) {
    .emoji:hover {
      transform: scale(1.2);
    }
  }
}

.theme-pink {
  .emoji-board {
    background: transparentize(lighten(#ff97da, 5%), 0.1);
  }
}
.theme-green {
  .emoji-board {
    background: transparentize(lighten(#00ed9e, 5%), 0.1);
  }
}
.theme-yellow {
  .emoji-board {
    background: transparentize(lighten(#ffe52c, 5%), 0.1);
  }
}
.theme-blue {
  .emoji-board {
    background: transparentize(lighten(#39d0ff, 5%), 0.1);
  }
}

@media (max-width: 650px) {
  .emoji-board {
    overflow: auto;
    .emoji-board-inner {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }

  .emoji-board-top {
    justify-content: center;
    .emoji {
      margin: 0 10px;
    }
    .emoji-btn {
      width: 130px;
      padding: 15px 25px;
      line-height: 1.3;
    }
  }
}

@media (max-width: 500px) {
  .emoji-board {
    .emoji-board-inner {
      padding: 30px 20px;
    }
  }
  .emoji-board-top {
    margin-bottom: 20px;
    .emoji {
      background: transparent;
    }
  }
  .emoji-list {
    .emoji {
      padding: 5px;
      img {
        width: 30px;
        cursor: pointer;
      }
    }
  }
  .chat {
    margin-bottom: 5px;
  }
}
