.emoji-input {
  margin: 20px 0;
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 260px;

  h1 {
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    margin: 10px 0 30px 0;
  }
}

.start-button {
  background: #0080ff;
  border: 0;
  border-radius: 50px;
  padding: 9px 15px 10px 15px;
  color: white;
  font-size: 20px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: 200ms;
  font-weight: 900;
}

@media (hover: hover) {
  .start-button:hover {
    background: lighten(#0080ff, 10%);
    transform: scale(1.05);
  }
}
