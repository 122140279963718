@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

html,
body {
  font-size: 16px;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

* {
  font-family: "Lato", sans-serif;
}
