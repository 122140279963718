.help-icon {
  width: 67px;
  height: 69px;
  background: url("../../assets/images/help-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 10;

  &.open {
    background: url("../../assets/images/help-icon-smile.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.help-content {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;

  .help-content--inner {
    background: #fff;
    width: 80%;
    max-width: 1000px;
    max-height: 90%;
    border-radius: 25px;
    padding: 50px 80px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-30%);
    opacity: 0;
    overflow: auto;
    cursor: default;

    .close-modal {
      position: absolute;
      top: -30px;
      right: -30px;
      width: 26px;
      height: auto;
    }

    h2 {
      margin: 0;
    }

    h3 {
      margin: 0 0 10px 0;
    }

    h3 {
      margin-top: 40px;
    }

    h2,
    h3 {
      font-size: 20px;
      font-weight: 900;
    }

    p {
      color: #ff78af;
      font-size: 16px;
      margin: 0;
    }

    .details {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 10px;

      span {
        font-size: 16px;
        display: block;
      }

      span:not(:last-child) {
        margin-bottom: 3px;
      }

      a {
        color: #888;
        text-decoration: none;
        transition: 200ms;
      }

      @media (hover: hover) {
        a:hover {
          text-decoration: underline;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > div {
        width: calc(50% - 60px);
      }
    }
  }
}

.help-icon.open {
  z-index: 200;
  .help-content {
    transition: 200ms;
    opacity: 1;
    pointer-events: all;
  }
  .help-content--inner {
    transition: 600ms;
    transform: translate(-50%, -50%);
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    opacity: 1;
  }
}

.theme-pink {
  .help-content {
    background: transparentize(lighten(#ff97da, 5%), 0.1);
  }
}
.theme-green {
  .help-content {
    background: transparentize(lighten(#00ed9e, 5%), 0.1);
  }
}
.theme-yellow {
  .help-content {
    background: transparentize(lighten(#ffe52c, 5%), 0.1);
  }
}
.theme-blue {
  .help-content {
    background: transparentize(lighten(#39d0ff, 5%), 0.1);
  }
}

@media (max-width: 1000px) {
  .help-content {
    .help-content--inner {
      .row {
        flex-direction: column;
        > div {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1230px) {
  .help-icon {
    bottom: auto;
    top: 30px;
  }
}

@media (max-width: 500px) {
  .help-icon {
    // width: 45px;
    // height: 46px;
    top: 12px;
    right: 5%;
  }
  .help-content {
    .help-content--inner {
      padding: 40px 20px;
      width: 90%;
      h3 {
        margin-top: 30px;
      }
    }
  }
}
